import { fromLonLat } from 'ol/proj';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MapContext from './MapContext';

//import { map } from './Map';

const SelectedDeviceMap = () => {
  const { map } = useContext(MapContext);

  const mapCenter = useSelector(state => {
    if (state.devices.selectedId) {
      const position = state.positions.items[state.devices.selectedId] || null;
      if (position) {
        return fromLonLat([position.longitude, position.latitude]);
      }
    }
    return null;
  });

  useEffect(() => {
    if (mapCenter) {
      map.getView().setCenter(mapCenter);
      map.getView().setZoom(17);
    }
    //map.easeTo({ center: mapCenter });
  }, [mapCenter, map]);

  return null;
}

export default SelectedDeviceMap;
