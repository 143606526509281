import React from 'react';
import { formatDate, formatPosition, formatSpeed } from '../common/formatter';
import { Grid, isWidthDown, makeStyles, SvgIcon, withWidth } from '@material-ui/core';

import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import SpeedIcon from '@material-ui/icons/Speed';
import PublicIcon from '@material-ui/icons/Public';
import { ReactComponent as SatIcon } from '../images/sat.svg';
import { ReactComponent as DateIcon } from '../images/date-icon.svg';
import { ReactComponent as MapIcon } from '../images/map-address-icon.svg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: "repeat(12, 1fr)",
    padding: "5px",
    gap: "5px",
  },
  tableCell: {
    color: 'white',
    fontSize: '1em',
    alignItems: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: "10px"
  },
}));

const PathMarkerElement = ({ icon = null, item = '', visible = true, xs = 12 }) => {
  const classes = useStyles();

  if (!visible) return null

  return (
    <div style={{ gridColumn: `span ${xs}` }} className={classes.tableCell}>
      <SvgIcon>
        {icon}
      </SvgIcon>
      <div>
        {item}
      </div>
    </div>
  );
}

const PathMarkerView = ({ item, width }) => {
  const classes = useStyles();

  let count = 0
  count += !!item.attributes.rssi ? 1 : 0
  count += !!item.attributes.sat ? 1 : 0
  count += !!item.attributes.power ? 1 : 0
  count += !!item.attributes.battery ? 1 : 0
  count = count !== 0 ? count : 1

  return (
    <div style={{ maxWidth: (isWidthDown('xs', width)) ? "85vw" : "35vw" }} className={classes.container}>
      <PathMarkerElement
        icon={<DateIcon />}
        item={formatDate(item.deviceTime)}
        xs={6}
      />
      <PathMarkerElement
        icon={<PublicIcon />}
        item={`${formatPosition(item.latitude, 'latitude')} ${formatPosition(item.longitude, 'longitude')}`}
        visible={!!item.latitude && !!item.longitude}
        xs={6}
      />
      <PathMarkerElement
        icon={<SpeedIcon />}
        item={formatSpeed(item.speed, 'kmh')}
        visible={!!item.speed}
      />
      <PathMarkerElement
        icon={<MapIcon />}
        item={item.address}
        visible={!!item.address}
      />
      <PathMarkerElement
        icon={<SignalCellularAltIcon />}
        item={`${item.attributes.rssi}%`}
        visible={!!item.attributes.rssi}
        xs={12 / count}
      />
      <PathMarkerElement
        icon={<SatIcon />}
        item={item.attributes.sat}
        visible={!!item.attributes.sat}
        xs={12 / count}
      />
      <PathMarkerElement
        icon={<SettingsPowerIcon />}
        item={`${formatPosition(item.attributes.power, 'speed')} v`}
        visible={!!item.attributes.power}
        xs={12 / count}
      />
      <PathMarkerElement
        icon={<BatteryChargingFullIcon />}
        item={formatPosition(item.attributes.battery, 'batteryLevel')}
        visible={!!item.attributes.battery}
        xs={12 / count}
      />
    </div>
  );
};

export default withWidth()(PathMarkerView);
