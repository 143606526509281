import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Paper, makeStyles, Grid, FormControlLabel, Checkbox, IconButton, Tooltip, Button } from '@material-ui/core';
import t from '../../common/localization';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import GetAppIcon from '@material-ui/icons/GetApp';
import { getPeriod } from '../../common/getPeriod';

const useStyles = makeStyles(theme => ({
  item: {
    flexGrow: 1
  },
  root: {
    width: '100%',
    display: 'flex',
    padding: '2px 4px',
    alignItems: 'center'
  },
  sticky: {
    position: 'sticky',
    top: 0
  }
}))

const ReportFilter = ({ children, filter, downloadReport, downloadOverspeed }) => {
  const classes = useStyles();

  const { period, setPeriod, from, setFrom, to, setTo, grouped, setGrouped, speedMarkers, setSpeedMarkers, stopsMarkers, setStopsMarkers } = filter;

  return (
    <Paper className={classes.sticky}>
      <Grid container direction="row" className={classes.root}>
        <Grid item>
          <FormControl className={classes.item} size='small'>
            <InputLabel>{t('reportPeriod')}</InputLabel>
            <Select value={period} onChange={(e) => setPeriod(e.target.value)}>
              <MenuItem value="today">{t('reportToday')}</MenuItem>
              <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
              <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
              <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
              <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
              <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
              <MenuItem value="custom">{t('reportCustom')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {period === 'custom' && (
          <Grid item >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
              <FormControl size='small' className={classes.item}>
                <DateTimePicker
                  autoOk
                  ampm={false}
                  disableFuture
                  value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                  onChange={value => { setFrom(moment(value, moment.HTML5_FMT.DATETIME_LOCAL)); console.log(moment(value, moment.HTML5_FMT.DATETIME_LOCAL)) }}
                  label={t('reportFrom')}
                  format="d MMMM HH:mm"
                />
              </FormControl>
              <FormControl size='small' className={classes.item}>
                <DateTimePicker
                  autoOk
                  ampm={false}
                  disableFuture
                  value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                  onChange={value => setTo(moment(value, moment.HTML5_FMT.DATETIME_LOCAL))}
                  label={t('reportTo')}
                  format="d MMMM HH:mm"
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
        )}

        <Grid item>
          <FormControl className={classes.item} size='small'>
            <InputLabel>Группировать</InputLabel>
            <Select value={grouped} onChange={(e) => setGrouped(e.target.value)}>
              <MenuItem value="trip">Поездки</MenuItem>
              <MenuItem value="day">День</MenuItem>
              <MenuItem value="all">Все</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {children}
        <Grid item style={{ marginLeft: 'auto' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={speedMarkers}
                onChange={(event) => { setSpeedMarkers(event.target.checked) }}
                name="speedMarkers"
                color="primary"
              />
            }
            label="Превышение скорости"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={stopsMarkers}
                onChange={(event) => { setStopsMarkers(event.target.checked) }}
                name="stopsMarkers"
                color="primary"
              />
            }
            label="Остановки"
          />
        </Grid>
        <Grid item>
          <Tooltip title='Скачать отчет'>
            <IconButton color="primary" aria-label="download" component="span" onClick={() => downloadReport()}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ReportFilter;
