import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { useContext, useEffect } from 'react';

import { Feature } from 'ol';
import MapContext from './MapContext';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import speed from '../images/speed.svg';
import parking from '../images/parking.svg';
import { withWidth } from '@material-ui/core';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import { formatSpeed } from '../common/formatter';
import { useSelector } from 'react-redux';
import ReportContext from '../reports/ReportContext';

const SpeedMarker = ({ route, speedMarkers }) => {
  const { speedMarkerSource } = useContext(MapContext);
  const { maxSpeed } = useContext(ReportContext);
  const { items: devices, selectedId: selectedDeviceId } = useSelector(state => state.devices);

  const createFeature = (label, position, type) => {
    var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));
    var iconStyle = new Style({
      image: new Icon({
        src: (type === 'speed') ? speed : parking,
        scale: 1,
        anchor: [0.5, 1],
      }),
      text: new Text({
        text: label ? label : '',
        scale: 1.5,
        offsetY: -45,
        overflow: true,
        backgroundFill: new Fill({
          color: [200, 200, 200, 0.6],
        })
      })
    });
    marker.setStyle(iconStyle);

    return marker
  };

  useEffect(() => {
    speedMarkerSource.clear();
    if (!route || route.length === 0) return;
    const overspeed = Number(devices[selectedDeviceId].attributes.speedLimit ?? 90)
    if (!speedMarkers) {
      if (maxSpeed)
        speedMarkerSource.addFeatures([createFeature(formatSpeed(maxSpeed.speed, 'kmh'), maxSpeed, 'speed')]);
    }else{
      let max = null;
      for (let i = 0; i < route.length; i++) {
        const speed = route[i].speed * 1.852
        const maxSpeed = (max?.speed ?? 0) * 1.852
        if (speed > overspeed && speed > maxSpeed)
          max = route[i]
        else if ((speed <= overspeed || i === route.length - 1) && max !== null){
          speedMarkerSource.addFeatures([createFeature(formatSpeed(max.speed, 'kmh'), max, 'speed')]);
          max = null
        }
      }
    }
  }, [route, speedMarkers, speedMarkerSource]);

  return null;

}

export default withWidth()(SpeedMarker);
