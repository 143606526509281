import React, { useState, useEffect } from 'react';
import { Fab, IconButton, isWidthDown, isWidthUp, makeStyles, Paper, Tooltip, withWidth } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import DevicesList from './DevicesList';
import MainToolbar from './MainToolbar';
import MapView from './map/MapView';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import ReplayPathMap from './map/ReplayPathMap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

import ReportLayout from './reports/ReportLayout'


import { Resize, ResizeVertical, ResizeHorizon } from "react-resize-layout";
import { useSelector } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';
import ReportContext from './reports/ReportContext';

import moment from 'moment';
import StopsMarker from './map/StopsMarker';
import SpeedMarker from './map/SpeedMarker';
import { getPeriod } from './common/getPeriod';
import config from './common/config';
import t from './common/localization';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    marginTop: '48px',
    maxHeight: "calc(100vh - 48px)"
    //overflow: 'hidden',
    /*flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    /*[theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    }*/
  },
  drawerPaper: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      height: '50vh'//250,
    }
  },
  mapContainer: {
    flexGrow: 1,
    position: 'relative',
    minHeight: '50vh',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      display: 'flex'
    }
  },
  drawerPaperBottom: {
    position: 'relative',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      height: '20vh',
      maxHeight: '20vh'
    },
    [theme.breakpoints.down('xs')]: {
      height: '50vh'//250,
    },
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2) + 48,
    right: theme.spacing(2),
    zIndex: 2
  },
}));

const MainPage = ({ width }) => {
  const classes = useStyles();

  const [center, setCenter] = useState([61.00263666666667, 61.178691666666666]);
  const [zoom, setZoom] = useState(3);
  const [, setChangeSizeMap] = useState(false);

  const [gpsFixed, setGpsFixed] = useState(false);
  const [isHideOtherDevices, setIsHideOtherDevices] = useState(false);
  const [openDevicesForm, setOpenDevicesForm] = useState(isWidthDown('xs', width));
  const [openRouteForm, setOpenRouteForm] = useState(false);

  const [period, setPeriod] = useState(!!localStorage.getItem('ReportFilterPeriod') ? localStorage.getItem('ReportFilterPeriod') : 'today');
  const [from, setFrom] = useState(!!localStorage.getItem('ReportFilterFrom') ? moment(localStorage.getItem('ReportFilterFrom')) : moment().subtract(1, 'hour'));
  const [to, setTo] = useState(!!localStorage.getItem('ReportFilterTo') ? moment(localStorage.getItem('ReportFilterTo')) : moment());
  const [grouped, setGrouped] = useState(!!localStorage.getItem('ReportFilterGrouped') ? localStorage.getItem('ReportFilterGrouped') : 'trip');//['trip', 'day', 'all']
  const [showOffline, setShowOffline] = useState(true)

  const deviceId = useSelector(state => state.devices.selectedId || null);
  const isSelectedTrip = useSelector(state => state.devices.isSelectedTrip || false);
  const curPositions = useSelector(state => state.positions.items);

  const [trips, setTrips] = useState([]);
  const [groupedItems, setGroupedItems] = useState([]);
  const [stops, setStops] = useState([]);
  const [positions, setPositions] = useState([]);
  const [maxSpeed, setMaxSpeed] = useState(null);
  const [events, setEvents] = useState([]);
  const [speedMarkers, setSpeedMarkers] = useState(false);
  const [stopsMarkers, setStopsMarkers] = useState(false);

  const downloadKML = () => {
    var kml = '<?xml version="1.0" encoding="UTF-8"?> <kml xmlns="http://www.opengis.net/kml/2.2"> <Document> <Style id="yellowLineGreenPoly"> <LineStyle> <color>#ff0000ff</color> <width>4</width> </LineStyle> <PolyStyle> <color>#ff0000ff</color> </PolyStyle> </Style> <Placemark> <styleUrl>#yellowLineGreenPoly</styleUrl> <LineString> <extrude>1</extrude> <tessellate>1</tessellate> <altitudeMode>clampToGround</altitudeMode> <coordinates> ';
    positions.forEach(position => {
      kml += `${position.longitude},${position.latitude},0\n `
    })

    kml += '</coordinates> </LineString> </Placemark> </Document> </kml>';;

    const blobUrl = URL.createObjectURL(new Blob([kml]));
    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = 'out.kml';

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    document.body.removeChild(link);
  }

  useEffect(() => {
    setPositions([])
  }, [deviceId]);

  useEffect(() => {
    localStorage.setItem('ReportFilterPeriod', period);
    const oldFrom = !!localStorage.getItem('ReportFilterFrom') ? moment(localStorage.getItem('ReportFilterFrom')) : moment().subtract(1, 'hour');
    const oldTo = !!localStorage.getItem('ReportFilterTo') ? moment(localStorage.getItem('ReportFilterTo')) : moment();
    const [newFrom, newTo] = getPeriod(period) ?? [oldFrom, oldTo]
    setFrom(newFrom)
    setTo(newTo)
  }, [period])

  useEffect(() => {
    localStorage.setItem('ReportFilterFrom', from);
  }, [from])

  useEffect(() => {
    localStorage.setItem('ReportFilterTo', to);
  }, [to])

  useEffect(() => {
    localStorage.setItem('ReportFilterGrouped', grouped);
  }, [grouped])

  useEffect(() => {
    if (!deviceId || isSelectedTrip) return
    const newPosition = curPositions[deviceId]
    if (!newPosition) return
    const newInPositions = positions.find(({id}) => id === newPosition.id)
    if (!!newInPositions) return
    const nowLast = new Date()
    nowLast.setMinutes(nowLast.getMinutes() - config.trackTimeWhenMoving)
    setPositions(state => [...state.filter(({ id, fixTime }) => new Date(fixTime) > nowLast), newPosition])
  }, [curPositions])

  return (
    <ReportContext.Provider value={{
      filter: {
        period: period,
        setPeriod: setPeriod,
        from: from,
        setFrom: setFrom,
        to: to,
        setTo: setTo,
        showOffline,
        setShowOffline,
        grouped,
        setGrouped,
        speedMarkers,
        setSpeedMarkers,
        stopsMarkers,
        setStopsMarkers
      },
      trips: trips,
      setTrips: setTrips,
      groupedItems: groupedItems,
      setGroupedItems: setGroupedItems,
      stops: stops,
      setStops: setStops,
      positions: positions,
      setPositions: setPositions,
      maxSpeed: maxSpeed,
      setMaxSpeed: setMaxSpeed,
      events: events,
      setEvents: setEvents,
      isHideOtherDevices: isHideOtherDevices
    }} >
      <div className={classes.root}>
        <MainToolbar />
        <Resize handleWidth={isWidthUp('sm', width) ? "5px" : "0px"} handleColor="#777" onResizeStop={() => { setChangeSizeMap(cur => !cur) }}>
          <ResizeHorizon
            width={isWidthUp('sm', width) ? "350px" : "0px"}
            handleWidth={isWidthUp('sm', width) ? "5px" : "0px"}
            className={classes.content}
          >
            {((isWidthUp('sm', width))) &&
              <DevicesList onSelect={(lon, lat, zoom) => {
                setCenter([lon, lat]);
                setZoom(zoom);
              }} />
            }
          </ResizeHorizon>
          <ResizeHorizon
            handleWidth={isWidthUp('sm', width) ? "5px" : "0px"}
            width="calc(100vw-48px)"
            className={classes.content}>
            <Resize handleWidth={isWidthUp('sm', width) ? "5px" : "0px"} handleColor="#777" onResizeStop={() => { setChangeSizeMap(cur => !cur) }}>

              <ResizeVertical
                height={isWidthUp('sm', width) ? "29vh" : "0px"}
                minHeight="10px"
              >
                <ReportLayout setCenter={setCenter} setZoom={setZoom} />
              </ResizeVertical>

              <ResizeVertical height={isWidthUp('sm', width) ? "71vh" : "100vh"} minHeight="10px">
                <MapView center={center} zoom={zoom}>
                  <CurrentPositionsMap />
                  {gpsFixed && <SelectedDeviceMap />}
                  <StopsMarker stops={stops} stopsMarkers={stopsMarkers} />
                  <SpeedMarker route={positions} speedMarkers={speedMarkers} />
                  <ReplayPathMap route={positions} setCenter={setCenter} setZoom={setZoom} isStartEnd={isSelectedTrip} />

                  <Tooltip title={isHideOtherDevices ? t('showOtherDevices') : t('hideOtherDevices')}>
                    <Fab 
                      size="medium" 
                      color="primary" 
                      className={classes.fab} 
                      onClick={() => setIsHideOtherDevices(state => !state)} 
                      style={{ bottom: '112px' }}
                      disabled={!deviceId}
                    >
                      {isHideOtherDevices ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                    </Fab>
                  </Tooltip>

                  <Tooltip title={!gpsFixed ? 'Следить за объектом' : 'Не следить за объектом'}>
                    <Fab 
                      size="medium" 
                      color="primary" 
                      className={classes.fab} 
                      onClick={() => setGpsFixed(cur => !cur)} 
                      style={{ bottom: '168px' }}
                      disabled={!deviceId}
                    >
                      {gpsFixed ? <GpsFixedIcon /> : <GpsNotFixedIcon />}
                    </Fab>
                  </Tooltip>
                  <Tooltip title='Скачать KML'>
                    <Fab 
                      size="medium" 
                      color="primary" 
                      className={classes.fab} 
                      onClick={() => { downloadKML() }} 
                      style={{ bottom: '224px' }} 
                      disabled={(positions.length === 0)}
                    >
                      <GetAppIcon />
                    </Fab>
                  </Tooltip>
                  {(isWidthDown('xs', width)) &&
                    <>
                      <Fab size="medium" color="primary" className={classes.fab} onClick={() => { setOpenDevicesForm(cur => !cur); }} style={{ bottom: '112px' }}>
                        <DirectionsCarIcon />
                      </Fab>
                      <Fab size="medium" color="primary" className={classes.fab}
                        onClick={() => { setOpenRouteForm(cur => !cur); }} style={{ bottom: '56px' }}
                        disabled={!deviceId}
                      >
                        <SwapCallsIcon />
                      </Fab>
                    </>
                  }
                </MapView>

                {(isWidthDown('xs', width)) &&
                  <Drawer anchor='bottom' open={true} onClose={() => setOpenDevicesForm(cur => !cur)} style={(!openDevicesForm) ? { display: 'none' } : {}} >

                    <Paper style={{
                      padding: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                      <strong style={{ flex: 1, marginLeft: '16px' }}>
                        Устройства
                      </strong>
                      <IconButton onClick={() => setOpenDevicesForm(cur => !cur)} style={{ float: 'right' }} aria-label="Закрыть">
                        <CancelIcon />
                      </IconButton>
                    </Paper>
                    <DevicesList onSelect={(lon, lat, zoom) => {
                      setOpenDevicesForm(cur => !cur)
                      setCenter([lon, lat]);
                      setZoom(zoom);
                    }} />
                  </Drawer>
                }
                {(isWidthDown('xs', width)) && openRouteForm &&
                  <Drawer anchor='bottom' open={openRouteForm} onClose={() => setOpenRouteForm(cur => !cur)}>
                    <Paper style={{
                      padding: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                      <strong style={{ flex: 1, marginLeft: '16px' }}>
                        Отчеты
                      </strong>
                      <IconButton onClick={() => setOpenRouteForm(cur => !cur)} style={{ float: 'right' }} aria-label="Закрыть">
                        <CancelIcon />
                      </IconButton>
                    </Paper>
                    <ReportLayout setCenter={setCenter} setZoom={setZoom} />
                  </Drawer>
                }
              </ResizeVertical>
            </Resize>
          </ResizeHorizon>
        </Resize>
      </div>
    </ReportContext.Provider>
  );
}

export default withWidth()(MainPage);
