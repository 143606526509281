import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Button, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import deviceAttributes from '../attributes/deviceAttributes';
import SelectField from '../form/SelectField';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const uploadImage = (callback) => {
  const isEmpty = (value) => value === null || value === undefined;
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.oninput = (e) => {
    if (!isEmpty(e.target.files) && !isEmpty(e.target.files[0])) {
      const images = [];
      for (const element of e.target.files) images.push(element);
      callback(images[0]);
    }
  };
  input.click();
};

function Image({ image = null, alt="", ...props }) {
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (!image) {
      setSrc("");
      return;
    }
    if (typeof image === "string") {
      setSrc(image);
      return;
    }
    const reader = new FileReader();
    reader.onload = (x) => {
      setSrc(x.target.result);
    }
    reader.readAsDataURL(image);
  }, [image]);

  return <img src={src} alt={alt} {...props}/>;
}

const CategoryPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState();
  const [image, setImage] = useState(null);

  const add = ()=>{
    uploadImage(image=>setImage(image))
  }

  const save = async (url, id) => {
    let formData = new FormData();
    formData.append("name", item.name)
    if (typeof (image ?? "") !== "string")
      formData.append("image", image)

    return await fetch(url, {
      method: !id ? 'POST' : 'PUT',
      body: formData,
    })
  }

  useEffect(()=>{
    if (!!item?.imageName) setImage(`/api/categories/image/${item?.imageName ?? ""}`)
  }, [item])

  return (
    <EditItemView endpoint="categories" item={item} setItem={setItem} save={save} >
      {item &&
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              margin="normal"
              value={item.name || ''}
              onChange={event => setItem({ ...item, name: event.target.value })}
              label={t('sharedName')}
              variant="filled" 
            />
            <div style={{display:"flex", justifyContent:"center", marginTop: "16px", marginBottom: "8px" }}>
              <Image image={image} style={{ maxWidth:"50%"}}/>
            </div>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={add}
              className={classes.addButton}
            >
              Загрузить картинку
            </Button>
          </AccordionDetails>
        </Accordion>
      }
    </EditItemView>
  );
}

export default CategoryPage;
