import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'scroll',
  },
  content: {
    flex: 1,
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
}));

const ReportLayoutPage = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid item xs={12} md={12} lg={12}>
          {children}
        </Grid>
      </div>
    </div>
  );
}

export default ReportLayoutPage;
