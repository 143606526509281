import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Feature } from 'ol';
import MapContext from './MapContext';
import arrowOffIcon from '../images/arrow-off.svg'
import startIcon from '../images/track-start.svg'
import endIcon from '../images/track-end.svg'
import Style from 'ol/style/Style';
import Stroke from "ol/style/Stroke";
import Icon from 'ol/style/Icon';
import { isWidthUp, withWidth } from '@material-ui/core';

const createMarkerFeature = (position, width) => {
  var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));

  var iconStyle = new Style({
    image: new Icon({
      src: arrowOffIcon,
      rotation: position.course * 0.017,
      scale: isWidthUp('sm', width) ? 0.5 : 1.5
    })
  });
  marker.setStyle(iconStyle);
  marker.set('position', position);

  return marker
};

const createStartFeature = (position, width) => {
  var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));

  var iconStyle = new Style({
    image: new Icon({
      src: startIcon,
      anchor: [0.5, 1],
      scale: isWidthUp('sm', width) ? 0.5 : 1.5
    })
  });
  marker.setStyle(iconStyle);
  marker.set('position', position);

  return marker
};

const createEndFeature = (position, width) => {
  var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));

  var iconStyle = new Style({
    image: new Icon({
      src: endIcon,
      anchor: [0.5, 1],
      scale: isWidthUp('sm', width) ? 0.5 : 1.5
    })
  });
  marker.setStyle(iconStyle);
  marker.set('position', position);

  return marker
};

const ReplayPathMap = ({ route, width, setZoom, isStartEnd=false }) => {
  const { routeSource, routeMarkerSource, routeStartEndSource } = useContext(MapContext);
  const { items: devices, selectedId: selectedDeviceId } = useSelector(state => state.devices);

  const getColor = (prev, cur, max = 90) => {
    if (prev < 40 && cur < 40) return [0, 255, 0, 1];
    if (prev < max && cur < max) return [255, 255, 0, 1];
    return [255, 0, 0, 1];
  }

  useEffect(() => {
    routeSource.clear();
    routeMarkerSource.clear();
    routeStartEndSource.clear();
    if (!route || route.length <= 1)  return;
    var MarkerFeatures = []
    let prev = null;
    
    const overspeed = Number(devices[selectedDeviceId].attributes.speedLimit ?? 90)
    route.forEach(position => {
      if (!!prev) {
        let ls = new Feature(new LineString([
          fromLonLat([prev.longitude, prev.latitude]),
          fromLonLat([position.longitude, position.latitude]),
        ]));
        ls.setStyle(new Style({
          stroke: new Stroke({
            color: getColor(prev.speed * 1.852, position.speed * 1.852, overspeed),
            width: 5,
          }),
        }));
        routeSource.addFeatures([ls]);
      }
      prev = position;
      MarkerFeatures.push(createMarkerFeature(position, width));
    });
    routeMarkerSource.addFeatures(MarkerFeatures);
    setZoom(17);
    if (isStartEnd && (route?.length ?? 0) > 2){
      routeStartEndSource.addFeatures([createStartFeature(route[0], width)])
      routeStartEndSource.addFeatures([createEndFeature(route[route.length - 1], width)]);
    }
  }, [route]);

  return null;

}

export default withWidth()(ReplayPathMap);
