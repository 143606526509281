import t from '../common/localization'
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate, formatPosition } from '../common/formatter';
import { makeStyles, SvgIcon, Table, TableBody, TableCell, TableRow, Box } from '@material-ui/core';
import SpeedIcon from '@material-ui/icons/Speed';
import PublicIcon from '@material-ui/icons/Public';
import { ReactComponent as DateIcon } from '../images/date-icon.svg';
import { ReactComponent as MapIcon } from '../images/map-address-icon.svg';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: "5px",
    color: 'white',
  },
  icon: {
    display: "flex",
  },
}));

const StatusView = ({ deviceId, onShowDetails }) => {
  const device = useSelector(state => state.devices.items[deviceId]);
  const position = useSelector(state => state.positions.items[deviceId]);

  const classes = useStyles();

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Box className={classes.icon}>
              <SvgIcon>
                <DateIcon />
              </SvgIcon>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>{formatDate(device.lastUpdate)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Box className={classes.icon}>
              <SvgIcon>
                <SpeedIcon />
              </SvgIcon>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>{formatPosition(position.speed, 'speed')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Box className={classes.icon}>
              <SvgIcon>
                <MapIcon />
              </SvgIcon>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>{position.address}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Box className={classes.icon}>
              <SvgIcon>
                <PublicIcon />
              </SvgIcon>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            {`${formatPosition(position.latitude, 'latitude')} ${formatPosition(position.longitude, 'longitude')}`}
          </TableCell>
        </TableRow>
        {position.attributes.batteryLevel &&
          <TableRow>
            <TableCell className={classes.tableCell}>{t('positionBattery')}</TableCell>
            <TableCell className={classes.tableCell}>{formatPosition(position.attributes.batteryLevel, 'batteryLevel')}</TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  );
};

export default StatusView;
