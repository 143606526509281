import React from 'react';
import { Switch, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import MainPage from './MainPage';
import LoginPage from './LoginPage';
import ServerPage from './admin/ServerPage';
import UsersPage from './admin/UsersPage';
import DevicePage from './DevicePage';
import UserPage from './UserPage';
import SocketController from './SocketController';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import GroupsPage from './settings/GroupsPage';
import GroupPage from './settings/GroupPage';
import PositionPage from './PositionPage';
import EventReportPage from './reports/EventReportPage';
import ReplayPage from './reports/ReplayPage';
import { useSelector } from 'react-redux';
import { colors, createMuiTheme, LinearProgress, ThemeProvider } from '@material-ui/core';
import SummaryReportPage from './reports/SummaryReportPage';
import ChartReportPage from './reports/ChartReportPage';
import DriversPage from './settings/DriversPage';
import DriverPage from './settings/DriverPage';
import ComputedAttributesPage from './settings/ComputedAttributesPage';
import ComputedAttributePage from './settings/ComputedAttributePage';
import CategoriesPage from './settings/CategoriesPage';
import CategoryPage from './settings/CategoryPage';

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      contrastText: '#ffffff',
      dark: colors.red[800],
      main: "#2e2e2e",
      light: colors.indigo[100]
    }
  },
});

const App = () => {
  const initialized = useSelector(state => !!state.session.server && !!state.session.user);
  const isAdmin = useSelector(state => state.session.user && state.session.user.administrator);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SocketController />
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <Route>
          {!initialized ? (<LinearProgress />) : (
            <Switch>
              <Route exact path='/' component={MainPage} />
              <Route exact path='/replay' component={ReplayPage} />
              <Route exact path='/position/:id?' component={PositionPage} />
              <Route exact path='/user/:id?' component={UserPage} />
              <Route exact path='/device/:id?' component={DevicePage} />
              {isAdmin && <Route exact path='/settings/notifications' component={NotificationsPage} />}
              {isAdmin && <Route exact path='/settings/notification/:id?' component={NotificationPage} />}
              {isAdmin && <Route exact path='/settings/categories' component={CategoriesPage} />}
              {isAdmin && <Route exact path='/settings/category/:id?' component={CategoryPage} />}
              {isAdmin && <Route exact path='/settings/groups' component={GroupsPage} />}
              {isAdmin && <Route exact path='/settings/group/:id?' component={GroupPage} />}
              {isAdmin && <Route exact path='/settings/drivers' component={DriversPage} />}
              {isAdmin && <Route exact path='/settings/driver/:id?' component={DriverPage} />}
              {isAdmin && <Route exact path='/settings/attributes' component={ComputedAttributesPage} />}
              {isAdmin && <Route exact path='/settings/attribute/:id?' component={ComputedAttributePage} />}
              {isAdmin && <Route exact path='/admin/server' component={ServerPage} />}
              {isAdmin && <Route exact path='/admin/users' component={UsersPage} />}
              {isAdmin && <Route exact path='/reports/event' component={EventReportPage} />}
              {isAdmin && <Route exact path='/reports/summary' component={SummaryReportPage} />}
              {isAdmin && <Route exact path='/reports/chart' component={ChartReportPage} />}
            </Switch>
          )}
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
