import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper, Link } from '@material-ui/core';
import t from '../../common/localization';
import { formatDistance, formatSpeed, formatHours, formatDate } from '../../common/formatter';
import { useAttributePreference } from '../../common/preferences';
import { useDispatch, useSelector } from 'react-redux';
import { devicesActions } from '../../store';

const Trips = ({ onClick, items }) => {
  const dispatch = useDispatch();
  const distanceUnit = useAttributePreference('distanceUnit');
  const [selectedRow, setSelectedRow] = useState(-1);
  const isSelectedTrip = useSelector(state => state.devices.isSelectedTrip || false);

  const onClickItem = (item, index) => ()=>{
    onClick(item); 
    setSelectedRow(index)
    dispatch(devicesActions.selectTrip());
  }

  useEffect(()=>{
    if (!isSelectedTrip) setSelectedRow(-1)
  }, [isSelectedTrip])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead >
          <TableRow>
            <TableCell>{t('reportStartTime')}</TableCell>
            <TableCell>{t('reportStartOdometer')}</TableCell>
            <TableCell>{t('reportEndTime')}</TableCell>
            <TableCell>{t('reportEndOdometer')}</TableCell>
            <TableCell>{t('sharedDistance')}</TableCell>
            <TableCell>{t('reportAverageSpeed')}</TableCell>
            <TableCell>{t('reportMaximumSpeed')}</TableCell>
            <TableCell>{t('reportDuration')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items && items.map((item, index) => (
            <TableRow key={index} onClick={onClickItem(item, index)} style={{ backgroundColor: (index === selectedRow) ? "lightgray" : "" }}>
              <TableCell>{formatDate(item.startTime)}</TableCell>
              <TableCell>{formatDistance(item.startOdometer, distanceUnit)}</TableCell>
              <TableCell>{formatDate(item.endTime)}</TableCell>
              <TableCell>{formatDistance(item.endOdometer, distanceUnit)}</TableCell>
              <TableCell>{formatDistance(item.distance, distanceUnit)}</TableCell>
              <TableCell>{formatSpeed(item.averageSpeed, "kmh")}</TableCell>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={(event) => { onClick(item, true); setSelectedRow(index); event.stopPropagation(); }}
                  style={{position: "static", color: "#00bcd4"}}
                >
                  {formatSpeed(item.maxSpeed, "kmh")}
                </Link>
              </TableCell>
              <TableCell>{formatHours(item.duration)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Trips;
