import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import t from './common/localization';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function DeviceFilterView({ filter, setFilter }) {
    const classes = useStyles();
    const [, setSearch] = useState();

    const changeVisibility = () => 
        setFilter({ ...filter, showOffline: !filter.showOffline })

    return (
        <Paper className={classes.root}>
            <IconButton 
                className={classes.iconButton} 
                color={filter.showOffline ? "primary" : ""} 
                aria-label="menu" 
                onClick={changeVisibility} 
                title={`${t('displayDevicesOffline')} (${filter.showOffline ? t('on') : t('off')})`}
            >
                {filter.showOffline ?
                    <VisibilityIcon /> :
                    <VisibilityOffIcon />
                }
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder={`${t("sharedSearch")}...`}
                fullWidth
                onChange={(event) => { setFilter({ ...filter, text: event.target.value }); }}
                defaultValue={filter.text}
                value={filter.text}
            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={() => { setSearch(''); setFilter({ ...filter, text: '' }) } }>
                <CloseIcon />
            </IconButton>
        </Paper>

    );
}