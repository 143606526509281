import moment from 'moment';
import 'moment/locale/ru';
import t from '../common/localization';

moment.locale('ru');

export const getPeriod = (period) => {
    const periods = {
        'today': [moment().startOf('day'), moment().endOf('day')],
        'yesterday': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
        'thisWeek': [moment().startOf('week'), moment().endOf('week')],
        'previousWeek': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'thisMonth': [moment().startOf('month'), moment().endOf('month')],
        'previousMonth': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
    return periods[period] ?? null
};
