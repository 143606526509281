import React, { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { devicesActions } from './store';
import EditCollectionView from './EditCollectionView';
import { useEffectAsync } from './reactHelper';
import { Box, Chip, Tooltip } from '@material-ui/core';
import DeviceFilterView from './DevicesFilter';
import moment from 'moment';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportContext from './reports/ReportContext';

import t from './common/localization';

const keyExpanded = "expanded"

const useStyles = makeStyles(theme => ({
  groups: {
    overflowY: "auto",
    overflowX: "hidden",
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    msOverflowStyle: "none",
    scrollbarWidth: "none"
  },
  list: {
    maxHeight: 'calc(100% - 96px)',
    overflow: "auto",
    [theme.breakpoints.down('xs')]: {
      height: '50vh',
    }
  },
  icon: {
    height: "40px",
    minWidth: "60px",
    width: "60px",
    margin: "10px 15px 10px 0px"
  },
  listItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  deviceName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between'
  },
  deviceNameLabel: {
    width: 'calc(100% - 40px);',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
}));

const filtring = (devicses, filter = null) => {
  let rezult = devicses;
  if (filter && filter.text)
    rezult = devicses.filter(device => {
      return device.name.toLowerCase().includes(filter.text.toLowerCase())
        || device.uniqueId.toLowerCase().includes(filter.text.toLowerCase())
    })

  if (filter && !filter.showOffline)
    rezult = rezult.filter(device => device.status === 'online')

  rezult = rezult.reduce(function (r, a) {
    r[a.groupId] = r[a.groupId] || [];
    r[a.groupId].push(a);
    return r;
  }, Object.create(null));

  Object.keys(rezult).map(group =>
    rezult[group] = rezult[group].sort((a, b) => {
      return a.status !== 'online';
    })
  )
  return rezult
}

const getFilter = () => {
  var filter = JSON.parse(localStorage.getItem('deviceFilter'));
  return (!!filter) ? filter : {
    text: null,
    showOffline: true
  }
}

const DeviceView = ({ updateTimestamp, onMenuClick, onItemClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filter: fl } = useContext(ReportContext);

  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(getFilter());
  const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem(keyExpanded)) ?? []);
  const IsScrolledRef = useRef(false)
  const scrollRef = useRef(null)

  const isAdmin = useSelector(state => state.session.user && state.session.user.administrator);

  useEffect(() => {
    localStorage.setItem('deviceFilter', JSON.stringify(filter));
    fl.setShowOffline(filter.showOffline)
  }, [filter])

  const items = useSelector(state => state.devices.items);
  const positions = useSelector(state => state.positions.items);
  const selectedID = useSelector(state => state.devices.selectedId);

  useEffectAsync(async () => {
    const response = await fetch('/api/categories');
    if (response.ok) {
      setCategories(await response.json());
    }
  }, [updateTimestamp]);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  useEffectAsync(async () => {
    const response = await fetch('/api/groups');
    if (response.ok) {
      let obj = {};
      let res = await response.json()
      res.map(item => obj[item.id] = item)
      setGroups(obj);
    }
  }, [updateTimestamp]);

  const lastUpdate = (time) => {
    let diff = moment().diff(time, 'second');
    if (diff < 60) return "<1м"
    if (diff < 3600) return `${Math.round(diff / 60)}м`
    if (diff < 86400) return `${Math.round(diff / 3600)}ч`
    if (diff < 604800) return `${Math.round(diff / 86400)}д`
    if (diff < 2419200) return `${Math.round(diff / 604800)}н`
    if (diff < 29030400) return `${Math.round(diff / 2419200)}м`
    return `${Math.round(diff / 29030400)}г`
  }

  let filtred = Object.keys(groups).length !== 0 ? filtring(Object.values(items), filter) : []

  const changeExpanded = (id) => (_, newExpanded) => {
    const newValues = newExpanded ? [...expanded, id] : expanded.filter(e => e !== id)
    setExpanded(newValues)
    localStorage.setItem(keyExpanded, JSON.stringify(newValues))
  };

  const getImageSrc = (id) => {
    const category = (id ?? 0) !== 0 ? categories.find(category => category.id === id) : null
    if (!category) return "images/icon/default.svg"
    return `/api/categories/image/${category.imageName}`
  }

  useEffect(() => {
    if (IsScrolledRef.current || filtred.length === 0) return
    IsScrolledRef.current = true
    scrollRef.current.scrollTo(0, Number(localStorage.getItem("scroll") ?? 0))
  }, [filtred])

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", gap: "10px" }}>
      <DeviceFilterView filter={filter} setFilter={setFilter} />
      <div style={{ flex: "1 0 auto", position: "relative" }}>
        <div ref={scrollRef} className={classes.groups} onScroll={(e) => localStorage.setItem("scroll", e.target.scrollTop)}>
          {Object.keys(filtred).map(groupId =>
            <Accordion key={groupId} expanded={(filter?.text?.length ?? 0) > 0 || expanded.indexOf(groupId) !== -1} style={{ margin: "0px" }} onChange={changeExpanded(groupId)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                {(groups && groups[groupId]) ? groups[groupId].name : <b>{t("noGroup")}</b>}
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px", display: "block" }}>
                <List className={classes.list} dense>
                  {filtred[groupId].map((item, index, list) => (
                    <Fragment key={item.id}>
                      <ListItem className={classes.listItem} button key={item.id}
                        onClick={() => {
                          dispatch(devicesActions.select(item));
                          !!positions[item.id] && onItemClick(positions[item.id].longitude, positions[item.id].latitude, 17)
                        }}
                        selected={selectedID === item.id}
                        style={{ paddingRight: "70px" }}
                      >
                        <img className={classes.icon} src={getImageSrc(item?.categoryId)} alt="" />
                        <ListItemText
                          primary={
                            <div className={classes.deviceName}>
                              <div className={classes.deviceNameLabel}>
                                {`${item.name}${!!item.stateNumber ? ` (${item.stateNumber})` : ``}`}
                              </div>
                              <Tooltip title={item.lastUpdate ? `${moment(item.lastUpdate).format('D MMM YYYY HH:mm:ss')} (${moment(item.lastUpdate).fromNow()})` : ''}>
                                <Chip
                                  label={!!item.lastUpdate ? lastUpdate(moment(item.lastUpdate)) : ''}
                                  size='small'
                                  style={{
                                    backgroundColor: (moment().diff(item.lastUpdate, 'second') < 3600) ? '#1cac00' :
                                      (moment().diff(item.lastUpdate, 'second') < 10800) ? '#daa620' : '#aa1313',
                                    color: 'white',
                                    width: '40px'
                                  }}
                                />
                              </Tooltip>
                            </div>
                          }
                          secondary={
                            <Tooltip title={(positions[item.id]) ? positions[item.id].address : ''}>
                              <Box component="div" textOverflow="ellipsis" overflow="hidden" className={classes.listItem} whiteSpace="nowrap">
                                {((positions[item.id]?.address ?? "") !== "") ? positions[item.id].address : ''}
                              </Box>
                            </Tooltip>
                          }
                        />
                        {isAdmin &&
                          <ListItemSecondaryAction>
                            <IconButton onClick={(event) => {
                              event.stopPropagation();
                              onMenuClick(event.currentTarget, item.id)
                            }}>
                              <MoreVertIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        }
                      </ListItem>
                      {index < list.length - 1 ? <Divider /> : null}
                    </Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>

          )}
        </div>
      </div>
    </div >
  );
}

const DevicesList = ({ onSelect }) => {
  return (
    <EditCollectionView content={DeviceView} editPath="/device" endpoint="devices" onItemClick={onSelect} title={null} />
  );
}

export default DevicesList;
