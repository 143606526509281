import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';

import t from './common/localization';
import RemoveDialog from './RemoveDialog';

const useStyles = makeStyles(theme => ({
  title: {
    margin: "20px 20px 10px",
    display: "flex",
    alignItems: "center",
    gap: "15px"
  },
}));

const EditCollectionView = ({ content, editPath, endpoint, onItemClick, title = "" }) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedId, setSelectedId] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const adminEnabled = useSelector(state => state.session.user && state.session.user.administrator);

  const menuShow = (anchorId, itemId) => {
    setSelectedAnchorEl(anchorId);
    setSelectedId(itemId);
  }

  const menuHide = () => {
    setSelectedAnchorEl(null);
  }

  const handleAdd = () => {
    history.push(editPath);
    menuHide();
  }

  const handleEdit = () => {
    edit(selectedId);
    menuHide();
  }

  const edit = (id) => {
    history.push(`${editPath}/${id}`);
  }

  const remove = (id) => {
    setSelectedId(id)
    handleRemove()
  }

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  }

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  }

  const Content = content;

  return (
    <>
      {title !== null &&
        <Box className={classes.title}>
          <Typography variant="h4">
            {title}
          </Typography>
          {adminEnabled &&
            <Fab size="small" color="primary" onClick={handleAdd}>
              <AddIcon />
            </Fab>
          }
        </Box>
      }
      <Content 
        updateTimestamp={updateTimestamp} 
        onMenuClick={menuShow} 
        onItemClick={onItemClick} 
        onEdit={edit} 
        onRemove={remove} 
      />
      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
        <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
        <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
      </Menu>
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />
    </>
  );
}

export default EditCollectionView;
