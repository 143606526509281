import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import Graph from './Graph';

const ChartReportPage = () => {

  const [items] = useState([]);
  const [type] = useState('speed');

  return (
    <>
      <ReportLayoutPage>
        <Graph items={items} type={type} />
      </ReportLayoutPage>
    </>
  )
}

export default ChartReportPage;
