import React, { useState } from 'react';
import MainToolbar from '../MainToolbar';
import { makeStyles, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';

const useStyles = makeStyles(theme => ({
  body: {
    display: "grid", 
    gridTemplateColumns: "repeat(4, 1fr)", 
    padding:"15px", 
    gap:"10px",
    '@media (max-width: 1280px)': {
      gridTemplateColumns: "repeat(3, 1fr)", 
    },
    '@media (max-width: 900px)': {
      gridTemplateColumns: "repeat(2, 1fr)", 
    },
    '@media (max-width: 720px)': {
      gridTemplateColumns: "repeat(1, 1fr)", 
    },
  },
  card: { 
    display: "flex", 
    alignItems: "center", 
    gap: "10px", 
    background:"#f7f7f7", 
    padding: "10px", 
    borderRadius: "10px", 
    border: "1px solid #e8eaee" 
  },
  name: { 
    flexGrow: "1",
  },
  icon: {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    verticalAlign: "top",
  },
  buttons: { 
    display: "flex" 
  }
}));

const CategoriesView = ({ updateTimestamp, onEdit, onRemove }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch('/api/categories');
    if (response.ok) {
      setItems(await response.json());
    }
  }, [updateTimestamp]);

  return (
    <div className={classes.body}>
      {items.map((item) => (
        <div key={item.id} className={classes.card}>
          <img className={classes.icon} src={`/api/categories/image/${item.imageName}`} alt="" />
          <span className={classes.name}>{item.name}</span>
          <div className={classes.buttons}>
            <IconButton onClick={() => onEdit(item.id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onRemove(item.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}

const CategoriesPage = () => {
  return (
    <>
      <MainToolbar />
      <EditCollectionView content={CategoriesView} editPath="/settings/category" endpoint="categories" title={t('settingsCategories')}/>
    </>
  );
}

export default CategoriesPage;
